.intervalPrevision {
  display: flex;
  flex-direction: column;
  width: 12%;
  margin: 20px 0;
}

.hour {
  font-size: 12px;
  text-align: start;
}

.weather {
  background-color: #f3f7e1;
  padding: 5px;
}
.weather img {
  width: 40%;
  padding: 5px;
}

@media screen and (max-width: 769px) {
  .hour {
    font-size: 9px;
  }
  .weather {
    background-color: #f6f6f6;
  }
  .weather img {
    width: 50%;
    padding: 3px;
  }
  .high, .middle, .low {
    font-size: 70%;
  }
}

.high {
  background-color: #f7b299;
  color: #f7b299;
}
.middle {
  background-color:#eddd6f;
  color: #eddd6f;
}
.low {
  background-color:#b9e38a;
  color: #b9e38a;
}