.dailyPrevision {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f6f6f6;
  margin: 10px 3px;
}
.selectedDailyPrevision {
  display: flex;
  flex-direction: column;
  background-color: #f3f7e1;
  width: 100%;
  margin: 10px 3px;
}

.day {
  background-color: #ededed;
  font-size: 11px;
  padding: 5px;
}
.selectedDay {
  background-color: #97bf0d;
  color: white;
  font-size: 11px;
  padding: 5px;
}
.day div:first-child, .selectedDay div:first-child {
  font-size: 14px;
  font-weight: bold;
}

.weatherIndicator {
  padding: 10px;
}

.prices, .selectedPrices {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.prices > * {
  background-color: #ededed;
  margin-bottom: 3px;
}
.selectedPrices > * {
  background-color: #e2ebbb;
  margin-bottom: 3px;
}

.firstTime, .secondTime, .thirdTime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.firstTime > *, .secondTime > *, .thirdTime > * {
  padding: 5px;
}


.highPrice {
  width: 20%;
  background-color:#f7b299;
  color: white;
  font-weight: bold;
}
.middlePrice {
  width: 20%;
  background-color:#eddd6f;
  color: white;
  font-weight: bold;
}
.lowPrice {
  width: 20%;
  background-color:#b9e38a;
  color: white;
  font-weight: bold;
}
.notProvidedPrice {
  width: 20%;
  background-color:#cbccc9;
  color: white;
  font-weight: bold;
}

.detail {
  cursor: pointer;
  padding: 10px;
  color: #97bf0d;
}
.detail span:first-child {
  margin-right: 4px;
}

@media screen and (max-width: 769px) {
  .dailyPrevision {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #f6f6f6;
  }
  .dailyPrevision > * {
    flex: 1;
  }

  .selectedDailyPrevision {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 10px 3px;
    background-color: #f6f6f6
  }
  .selectedDailyPrevision > * {
    flex: 1;
  }

  .day {
    flex-basis: 25%;
    background-color: #f6f6f6;
    text-align: left;
    font-size: 11px;
    padding: 10px;
  }
  .selectedDay {
    flex-basis: 25%;
    background-color: #f6f6f6;
    color: inherit;
    text-align: left;
    font-size: 11px;
    padding: 10px;
  }

  .weatherIndicator {
    flex-basis: 35%;
  }

  .prices, .selectedPrices {
    flex-basis: 40%;
  }
  .prices > *, .selectedPrices > * {
    background-color: #ededed;
    height: 31.33%;
    margin-bottom: 0;
  }

  .firstTime > *, .secondTime > *, .thirdTime > * {
    padding-top: 10px;
  }

  .detail {
    position: absolute;
    background-color: #f6f6f6;
    cursor: pointer;
    padding: 0;
    margin-top: 90px;
    margin-left: 10px;
    color: #97bf0d;
  }

}