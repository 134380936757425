.weatherTable, .weatherTableDetail {
  display: flex;
  justify-content: space-around;
}

.dailyPrevisionContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 14%;
}

.noIntervalPrevisions {
  width: 100%;
  background-color: #f3f7e1;
  margin-left: 3px;
  padding: 20px;
}

@media screen and (max-width: 769px) {
  .weatherTable {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .noIntervalPrevisions {
    width: 100%;
    background-color: #f6f6f6;
    margin-left: 3px;
    padding: 20px;
  }
}